import * as React from "react";
import Layout from "../components/layout";

const WritingsPage = () => {
  return (
    <main>
      <Layout>
        <h1> Writings</h1>
      </Layout>
    </main>
  );
};
export default WritingsPage;
