import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

// Import Components
import Billboard from "../components/home/billboard.js";
import Shots from "../components/home/shots.js";
import Projects from "../components/home/projects/projects.js";

// markup
const WorkPage = () => {
  return (
    <main>
      <Seo title="Bishal Mishra, UX &amp; Product Designer" />
      <Layout>
        <Billboard
          title={`I design <br> Interfaces`}
          description={`<p>
            I am a Product Designer who turns<br>complex problem sets to simple solutions, focused to build experiences.
          </p>`}
          linkUrl={`/about/`}
          linkTitle={`go to about page`}
          linkData={[
            {
              linkUrl: `/about/`,
              linkTitle: `About Me`,
            },
          ]}
          workTitle={`<p><span>forte</span>UX &<br>Product</p>`}
        />
        <Shots
          title={`One of a kind designer who takes idea from paper sketch to real excellent product.`}
        />
        <Projects title={`<span>projects</span>`} />
      </Layout>
    </main>
  );
};

export default WorkPage;
