import * as React from "react";
import SvgSprite from "../components/parts/svg-sprite";

const Footer = () => {
  return (
    <footer>
      <h2>Footer</h2>
      <SvgSprite title="Svg Sprite" />
    </footer>
  );
};
export default Footer;
