import * as React from "react";
import Layout from "../components/layout";

const AboutPage = () => {
  return (
    <main>
      <Layout>
        <h1> About</h1>
      </Layout>
    </main>
  );
};
export default AboutPage;
